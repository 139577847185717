import React, { Component } from 'react';
import { Col } from 'reactstrap';
import renderHTML from 'react-render-html';

import './SimplePhoto.css'

class SimplePhoto extends Component {
  render() {
    return (
      <Col className="simple-photo py-5">
        <a href={this.props.photo.link} target={this.props.photo.target}>
          <div className={"img-container d-flex align-items-center"}>
            <img className='img-fluid responsive' alt={this.props.photo.alt} src={this.props.photo.src} />
          </div>
          <p className="mt-4 clubs-name">
            {renderHTML(this.props.photo.title)}
            </p>
        </a>
      </Col>
    )
  }
}

export default SimplePhoto;
