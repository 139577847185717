import React, { Component } from 'react';
import './SectionText.css';
import { Container, Row, Col } from 'reactstrap';
import renderHTML from 'react-render-html';

class SectionImageText extends Component {
	render() {
		return (
			<section id={this.props.lang.anchor} className='section-text'>
				<Container className='full-height'>
					<Row>
						<Col xs="12">
							<h2 className="text-left text-lg-center font-weight-bold">{this.props.lang.title}</h2>
							<p className="text text-left text-lg-center">
								{renderHTML(this.props.lang.text)}
							</p>
						</Col>
					</Row>
				</Container>
			</section>
		)
	}
}

export default SectionImageText;
