import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import './SectionClaim.css';
import renderHTML from 'react-render-html';

class SectionClaim extends Component {
	render() {
    return (
      <div className="claim-content d-flex align-items-center h-100">
        <p className="head">
                {renderHTML(
                    this.props.lang.head
                )
            }</p>
        <div className="main-content">
            <img class="mb-3 logo-callroom" src="/img/logo.png" />
            <h1><span class="fw-bold color-1">CALL</span><span class="fw-bold color-2">ROOM</span></h1>
            <h3>{this.props.lang.title}</h3>
            <p className="text mb-lg-5">{renderHTML(this.props.lang.text)}</p>
            {
            this.props.lang.ctas.map((cta, i) => {
                return <div class="btn-container"><a target={cta.target} href={cta.link} className={cta.class}>{cta.text}</a></div>
            })
            }
        </div>
      </div>
    )
  }
}

export default SectionClaim;
