import React, {Component} from 'react';
import './SectionResponsive.css';
import {Container, Row, Col} from 'reactstrap';

class SectionResponsive extends Component {
	render() {
		return (
			<section id={this.props.lang.anchor} className='section-responsive'>
				<Container className='full-height'>
					<Row>
						<Col xs="12">
							<h2 className="text-center">{this.props.lang.title}</h2>
							<p className="text">{this.props.lang.text}</p>
						</Col>
						<Col xs="12">
							<img className="responsive img-fluid d-none d-lg-block" alt="unsplash" src={this.props.lang.image}/>
							<img className="responsive img-fluid d-block d-lg-none" alt="unsplash"
										src={this.props.lang.image_mobile}/>
						</Col>
					</Row>
				</Container>
			</section>
		)
	}
}

export default SectionResponsive;
