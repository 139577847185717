import React, { Component } from 'react';
import SimplePhoto from '../SimplePhoto/SimplePhoto.js'
import { Row } from 'reactstrap';

import './ListPhotos.css'

class ListPhotos extends Component {
  render() {
    return (
      <Row className="image-box pt-2 pb-0 pb-md-5">
        {
          this.props.gallery.map((elem, i) => {
            return <SimplePhoto key={i} photo={elem} />
          })
        }
      </Row>
    )
  }
}

export default ListPhotos;
