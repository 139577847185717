import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="p-4 container">
          <div className="credits">
            <Row>
              <Col xs="12" md="6">
                <div className="py-2">
                  { this.props.lang.credits.description }
                </div>
                <div className="logos">
                {
                  Array.isArray(this.props.lang.credits.logos) ?
                    this.props.lang.credits.logos.map((logo, index) => {
                      return (
                        <a target="_blank" href={ logo.link } key={index}>
                          <img alt="logo" src={ logo.image } />
                        </a>
                      );
                    }) : ''
                }
                </div>
              </Col>
              <Col className="col-right" xs="12" md="6">
                <div className="py-2">
                  Altre pagine:
                </div>
                <div className="">
                  {
                    Array.isArray(this.props.lang.credits.menu) ?
                      this.props.lang.credits.menu.map(txt => {
                        let content = (txt.link) ? (<a target={txt.target} href={txt.link}>{txt.text}</a>) : (txt.text)
                        return (
                          <div key={txt.id} className={txt.class}>
                            {content}
                          </div>
                        )
                      }) : ''
                  }
                </div>
              </Col>
            </Row>
            <div className="other-info py-4">
              {
                Array.isArray(this.props.lang.credits.text) ?
                  this.props.lang.credits.text.map(txt => {

                    let content = (txt.link) ? (<a target={txt.target} href={txt.link}>{txt.text}</a>) : (txt.text)
                    return (
                      <div key={txt.id} className={txt.class}>
                        {content}
                      </div>
                    )
                  }) : ''
              }
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;
